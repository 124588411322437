var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import Editor from '../editor';
export default function RHFEditor(_a) {
    var name = _a.name, helperText = _a.helperText, showLinkVideoImage = _a.showLinkVideoImage, other = __rest(_a, ["name", "helperText", "showLinkVideoImage"]);
    var _b = useFormContext(), control = _b.control, watch = _b.watch, setValue = _b.setValue, isSubmitSuccessful = _b.formState.isSubmitSuccessful;
    var values = watch();
    useEffect(function () {
        if (values[name] === '<p><br></p>') {
            setValue(name, '', {
                shouldValidate: !isSubmitSuccessful,
            });
        }
    }, [isSubmitSuccessful, name, setValue, values]);
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Editor, __assign({ id: name, value: field.value, onChange: field.onChange, error: !!error, showLinkVideoImage: showLinkVideoImage, helperText: (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error, sx: { px: 2 } }, { children: error ? error === null || error === void 0 ? void 0 : error.message : helperText }))) }, other)));
        } }));
}
